@import "../../css/variables.scss";

.home {
  height: 100vh;
  background: url('https://i.insider.com/5d0d02fde3ecba05703614b3?width=1100&format=jpeg&auto=webp');
  background-repeat: no-repeat;
  background-size: cover;
  
  .background {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .85;
  background: linear-gradient(45deg, $primary, $secondary );
  background-size: 400% 400%;
  -webkit-animation: gradientBG 10s ease infinite;
  animation: gradientBG 10s ease infinite;
  position: absolute;
  z-index: 1;
  
  .jumbotron {
    background: transparent;
    z-index: 5;
  
    .jumbotron-heading {
      color: $white;
    }

    .text-description {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
}

@-webkit-keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

