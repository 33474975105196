$darkLiver: rgba(84, 73, 75, 1);
$isabelline: rgba(241, 247, 237, 1);
$etonBlue: rgba(145, 199, 177, 1);
$dingyDungeon: rgba(179, 57, 81, 1);
$flax: rgba(227, 208, 129, 1);

// $theme-colors: (
//   "primary": $isabelline,
//   "secondary": $darkLiver,
//   "light": $etonBlue,
//   "dark": $dingyDungeon
// );

// $white: $flax;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 700px,
//   md: 900px,
//   lg: 1200px,
//   xl: 1500px,
//   xxl: 2300px
// );

// $container-max-widths: (
//   sm: 600px,
//   md: 800px,
//   lg: 1100px,
//   xl: 1300px,
//   xxl: 2000px
// );

@import "../../node_modules/bootstrap/scss/bootstrap";
