.features {
  .feature-description {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
    }
  }

  .row:nth-child(2n) > .feature-asset {
    @media (min-width: 768px) {
      order: 2;
    }
  }

  .feature-asset {
    display: flex;
    justify-content: center;
    align-items: center;

    .feature-asset-image-container {
      .feature-asset-image {
        width: 100%;
        border-radius: 50%;
        max-width: 20rem;
        max-height: 20rem;
      }
    }
  }
}