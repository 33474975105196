.benefits-container {
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.benefits .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.benefits h2 {
  font-weight: 400;
}
.benefits .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}